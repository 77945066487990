import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Navbar from "../components/Navbar"
import PageSection from '../components/PageSection'


const PrivacyPolicy = ({ data }) => (
  <Layout>
    <SEO title="Privacy Policy" description="Privacy notice and GDPR" keywords="" lang="en" />

    <Navbar />

    <PageSection heading="PRIVACY NOTICE and GDPR">
      <p className="has-text-justified">Privacy policy updated on 01 March 2019 </p>
      <p className="has-text-justified">This Privacy Notice explains what personal data I collect, how I use your personal data, reasons I may need to disclose your personal data to others and how I store your personal data securely. For clarity, I may be both data controller and data processor for your personal data under certain circumstances. This policy is subject to change.</p>
      <p className="has-text-justified">This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
      <p className="has-text-justified">Please note that this Privacy Policy applies only to Heal to Thrive and not to any third-party sites that are linked to from our site.</p>
      <p className="has-text-justified">The reasons I process your personal data include, but are not limited to, your consent, performance of a contract, billing and to contact you.</p>
      <p className="has-text-justified">I receive information about you from you when you use my website, complete forms on my website or in person, if you contact me by phone, email or otherwise. I also collect information from you when you sign up for my mailing lists or when you inform me of any other matter.</p>
      <p className="has-text-justified">The personal data that I may collect from you includes your name, address, email address, phone numbers, payment information and IP addresses. I keep notes in accordance with the law and British Association for Counselling and Therapy (BACP) guidelines. I may also retain records of your queries and correspondence, in the event you contact me.</p>
      <h3 style={{ marginTop: '50px', textAlign: 'left', fontWeight: '300' }}>How I use your data</h3>
      <p className="has-text-justified">I use information about you in the following ways:</p>
      <ul className="general-list">
        <li>To process bookings and/or orders that you have made with me</li>
        <li>To provide you with products and services</li>
        <li>To comply with my contractual obligations with you</li>
        <li>To enable me to review, develop and improve my website and services</li>
        <li>To provide customer care, including responding to your requests if you contact me with a query</li>
        <li>To keep track of billing and payments</li>
        <li>To carry out marketing and statistical analysis</li>
        <li>To notify you about changes to my website and services</li>
        <li>To provide you with information about products or services that you request from me or which I feel may interest you, where you have consented to be contacted for such purposes</li>
        <li>To inform you of service</li>
      </ul>

      <h3 style={{ marginTop: '50px', textAlign: 'left', fontWeight: '300' }}>Your rights</h3>
      <p className="has-text-justified">In preventing the use or processing of your personal data, it may delay or prevent me from fulfilling our contractual obligations to you. It may also mean that I am unable to provide my services.</p>
      <p className="has-text-justified">You have the right to object to my use of your personal data, or ask me to delete, remove or stop using it if there is no need for me to keep it. This is known as your right to be forgotten. There are legal and accountancy reasons why I will need to keep your data, but please do inform me if you think I am retaining or using your personal data incorrectly.</p>
      <p className="has-text-justified">Accessing and updating your data: Please let me know if your information (email address etc) changes. You have the right to access the information I hold about you. </p>
      <p className="has-text-justified">Visitors to our websites: We use a third-party service, Google Analytics, to collect standard internet log information and details of visitor behaviour patterns. We do this to find out things such as the number of visitors to the various parts of the site. This information is only processed in a way which does not identify anyone. We do not make, and do not allow Google to make, any attempt to find out the identities of those visiting our website. If we do want to collect personally identifiable information through our website, we will be up front about this. We will make it clear when we collect personal information and will explain what we intend to do with it. Visitors may opt out of our use of Analytics by visiting the Google Analytics Opt-out age.</p>
      <p className="has-text-justified">Use of cookies: My website uses cookies – small text files that are placed on your machine to help the site provide a better user experience. In general, cookies are used to retain user preferences, store information for things like shopping carts, and provide anonymised tracking data to third party applications like Google Analytics. As a rule, cookies will make your browsing experience better. However, you may prefer to disable cookies on this site and on others. The most effective way to do this is to disable cookies in your browser. We suggest consulting the Help section of your browser or taking a look at the About Cookieswebsite which offers guidance for all modern browsers </p>
      <p className="has-text-justified">Security and performance: We use a third-party service to help maintain the security and performance of healtothrive.co.uk. To deliver this service it processes the IP addresses of visitors to the healtothrive.co.uk.</p>
      <p className="has-text-justified">Links to other sites: Since I do not control other websites, I encourage you to review the privacy policies of any third party sites. Any information that is supplied on these sites is not within control and I cannot be responsible for their privacy policies and practices.</p>
      <p className="has-text-justified">Phone calls: When you call me I will only collect the information necessary to ensure you receive an efficient and appropriate response. We may also retain some of the information where it helps with providing you with an efficient response. We will not keep any data from you for longer than is necessary.</p>
      <p className="has-text-justified">When you email me: If your email service does not support TLS or SSL or similar security systems, you should be aware that any emails we send or receive may not be protected in transit.</p>
      <p className="has-text-justified">We will monitor any emails sent to us, including file attachments, for viruses or malicious software. Please be aware that you have a responsibility to ensure that any email you send is within the bounds of the law. We will not keep any email data from you for longer than is necessary.</p>
      <p className="has-text-justified">I follow legal standards to keep digital data password protected and secure. </p>
      <p className="has-text-justified">As the transmission of information via the internet is not completely secure, I cannot guarantee the security of your data transmitted to my site and any transmission is at your own risk. Once I have received your information, I will use strict procedures and security features to prevent unauthorised access.</p>
      <p className="has-text-justified">The GDPR replaces the 1998 Data Protection Act to ensure your personal and sensitive, confidential data is kept private and held securely, being processed in the way that you have agreed to. It is there to protect your rights as a consumer of a service or product that might involve your identifiable data, e.g. your name and address or whether you have a specific condition. It also covers any session records, text messages or emails we exchange. </p>
      <p className="has-text-justified">I must hold your data for as long as is reasonable. My insurance policy determines that to be 5 years. I may not be able to delete your data before this time due to legal and/or accountancy obligations. I shall retain your data only for as long as necessary in accordance with applicable laws.</p>
      <p className="has-text-justified">Exceptions:  In order to safeguard you and the people around you, if you were to disclose that you were going to carry out harm to yourself or someone else, then under my “Duty of Care” I am obligated to inform the relevant authorities. This is to support you to live well, and I would always aim to discuss this with you prior to contacting anyone. </p>
      <p className="has-text-justified">Under the Data Protection Act 1998 and soon the General Data Protection Regulations (GDPR), you have rights as an individual which you can exercise in relation to the information we hold about you.</p>
      <p className="has-text-justified">If you want to make a complaint about the way we have processed your personal information, you can contact the Information Commissioners Office (ICO) in their capacity as the statutory body which oversees data protection law in the UK.</p>
      <p className="has-text-justified">If you wish to withdraw your consent, please send an email to healtothrivetherapy@gmail.com. A record of your request to withdraw consent may still be retained so that we can ensure you are no longer contacted.</p>
      <p className="has-text-justified">This privacy notice was drafted with brevity and clarity in mind. It does not provide exhaustive detail of all aspects of collection and use of personal information. However, we are happy to provide any additional information or explanation needed. </p>
      
    </PageSection>

  </Layout>
)


export default PrivacyPolicy
